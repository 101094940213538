import Axios from "axios";
import { jsonToQuery } from "../utils";
import callApi from "../utils/callApi";
import { ServiceUrl } from "../utils/serviceUrl";

export const listSettingsFields = async () => {
  return new Promise((resolve, reject) => {
    Axios.get(ServiceUrl.SETTINGS)
      .then(function ({ data }) {
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        return [];
      });
  });
};

export const _ListGallery = async (searchData = false) => {
  let url = ServiceUrl.LIST_GALLERY;
  if (searchData) url += "?" + jsonToQuery(searchData);
  return callApi(url);
};

export const _HOME_PAGE_SETTINGS = async (searchData = false) => {
  let url = ServiceUrl.HOME_PAGE_SETTINGS;
  return callApi(url, "post", searchData && searchData);
};

export const _GET_PAGE = async (slug, searchData = false) => {
  let url = ServiceUrl.GET_PAGE;
  let num = Number.isFinite(Number(slug));

  if (num) url += "?id=" + slug;
  else url += "?slug=" + slug;

  return callApi(url, "post", searchData && searchData);
};

export const _LIST_CONTENTS = async (searchData = false) => {
  let url = ServiceUrl.LIST_PAGES;
  if (searchData) url += "?" + jsonToQuery(searchData);
  return callApi(url);
};
